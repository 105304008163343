export function webPSupported ():boolean {
	if (process.client) {
		const elem = document?.createElement('canvas')

		if (elem.getContext && elem.getContext('2d')) {
			return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
		} else {
			return false
		}
	}
}

export function imageSrc (imgUrlStr: string | undefined, widthOrOptions?: number | {
	[p: string]: string,
}, height?: number) {
	if (!imgUrlStr?.includes('media-cdn.incrowdsports.com') || imgUrlStr?.includes('.svg')) return imgUrlStr
	const imgUrl = new URL(imgUrlStr)
	const imgParams = new URLSearchParams(imgUrl.search)
	if (widthOrOptions && typeof widthOrOptions !== 'number') {
		Object.keys(widthOrOptions).forEach(key => {
			imgParams.set(key, widthOrOptions[key].toString())
		})
	}
	if (typeof widthOrOptions === 'number') imgParams.set('width', widthOrOptions.toString())
	if (height) imgParams.set('height', height.toString())
	if (webPSupported()) imgParams.set('format', 'webp')
	imgUrl.search = imgParams.toString()
	return imgUrl.href
}

export const computeUrl = (source = '', width?: string, format?: string): string => {
	if (!source.includes('media-cdn.incrowdsports.com')) return source
	try {
		const url = new URL(source)
		const searchParams = url.searchParams
		// delete the already added biggestWidthLimit

		if (width) {
			searchParams.set('width', width)
		}
		if (format) {
			searchParams.set('format', format)
		}
		return url.toString()
	} catch {
		return ''
	}
}

export function computeScaledSrcSets (src: string, breakpoints: string[] | number[] | {screen: number, image: number}[] = ['320', '360', '640', '720', '960', '1280', '1440', '1920'], largestSize?: number, format = 'webp') {
	const sizes = (largestSize ? [...breakpoints, largestSize] : breakpoints).map((size:string | number | {screen: number, image: number}) => {
		if (typeof size === 'number' || typeof size === 'string') {
			size = { screen: <number>size, image: <number>size }
		}
		let srcset = ''
		for (let i = 1; i <= 3; i++) {
			srcset += `${computeUrl(src, `${size.image * i}`, format)} ${i >= 1 ? `${i}x,` : ''}`
		}

		srcset = srcset.slice(0, -1)

		const media = `(max-width: ${size.screen}px)`

		const url = computeUrl(src, `${size.image}`, format)

		return {
			media,
			srcset,
			size,
			url,
		}
	})
	return sizes
}
